import React, { useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import "bootstrap/dist/css/bootstrap.min.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./WebcamCapture.css";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import * as math from "mathjs";
import * as faceapi from "face-api.js";
import axios from "axios";
import {
  ArrowLeft,
  Camera,
  Info,
  Warning,
  FaceMask,
  Sun,
  HandPalm,
  WarningCircle,
} from "@phosphor-icons/react";

import defaultData from "../../data/default.json";
import toast from "react-hot-toast";

const WebcamCapture = ({ onCapture }) => {
  const [almostDoneString, setAlmostDoneString] = useState(false);
  const webcamRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fps, setFps] = useState([]);
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const userId = searchParams.get("userid");
  const name = searchParams.get("name");
  const height = searchParams.get("height");
  const weight = searchParams.get("weight");
  const age = searchParams.get("age");
  const gender = searchParams.get("gender");
  const [loader, setLoader] = useState(false);
  const averageFpsValuesRef = useRef([]);
  const [isScanning, setIsScanning] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const [isResizing, setIsResizing] = useState(false);
  const resizeTimeoutRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (capturing) {
        setViewportHeight(window.innerHeight);
        return;
      }

      setIsResizing(true);

      // Clear any existing timeout
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      // Set a new timeout
      resizeTimeoutRef.current = setTimeout(() => {
        setViewportHeight(window.innerHeight);
        setIsResizing(false);
      }, 300); // 300ms debounce
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, [capturing]);

  const getGuideSize = () => {
    const baseSize = Math.min(viewportHeight * 0.45, 400); // 45% of viewport height, max 400px
    return {
      small: Math.round(baseSize * 0.8), // for mobile
      large: Math.round(baseSize), // for desktop
    };
  };

  const guideSize = getGuideSize();

  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = "/models";
      await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
      await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
    };
    loadModels();
  }, []);

  useEffect(() => {
    if (capturing && !isResizing) {
      capture();
    }
  }, [capturing, isResizing]);

  function generateDefaultValue(age = 25, gender = "male", height = 170, weight = 80) {
    const sysbp = (100 + Math.random() * 20).toFixed(0); // Systolic BP: 100-120 mmHg
    const diabp = (60 + Math.random() * 20).toFixed(0); // Diastolic BP: 60-80 mmHg
    const hr = ((parseFloat(sysbp) + parseFloat(diabp)) / 2 + Math.random() * 10).toFixed(0); // Heart Rate: derived from BP

    const defaultValues = {
      HB: (gender === 'male' ? (13.5 + Math.random() * 3) : (12 + Math.random() * 2)).toFixed(1), // 12-16 for female, 13.5-17.5 for male
      HBA1C: (4.5 + Math.random() * 1.5).toFixed(1), // Normal: 4.5-6
      asth_risk: Math.random() > 0.5 ? 1 : 0, // Either 0 or 1
      bf: (gender === 'male' ? (10 + Math.random() * 15) : (18 + Math.random() * 15)).toFixed(1), // Male: 10-25, Female: 18-33
      bmi: (18.5 + Math.random() * 6.5).toFixed(1), // Normal BMI range: 18.5-25
      bv: (4000 + Math.random() * 2000).toFixed(1), // Random blood volume in ml
      bwp: (30 + Math.random() * 15).toFixed(1), // Body Water Percentage: 30-45
      co: (4 + Math.random() * 2).toFixed(1), // Cardiac Output: 4-6 L/min
      "debug-hr_try": hr,
      diabp: diabp,
      hr: hr,
      hrr: (40 + Math.random() * 20).toFixed(1), // Heart Rate Recovery: 40-60
      hrv: (0.5 + Math.random() * 0.5).toFixed(1), // HRV: 0.5-1
      hu: (50 + Math.random() * 10).toFixed(1), // Hydration Units: 50-60
      ibi: (500 + Math.random() * 200).toFixed(1), // Interbeat Interval: 500-700 ms
      map: (80 + Math.random() * 10).toFixed(1), // Mean Arterial Pressure: 80-90 mmHg
      mhr: (180 - age).toFixed(1), // Max Heart Rate: 220 - age
      pnn20: (70 + Math.random() * 20).toFixed(1), // HRV PNN20: 70-90
      pnn50: (60 + Math.random() * 20).toFixed(1), // HRV PNN50: 60-80
      rmssd: (100 + Math.random() * 100).toFixed(1), // RMSSD: 100-200
      rr: (12 + Math.random() * 6).toFixed(1), // Respiration Rate: 12-18 bpm
      sdnn: (100 + Math.random() * 50).toFixed(1), // SDNN: 100-150 ms
      si: (30 + Math.random() * 10).toFixed(1), // Stress Index: 30-40
      spo2: (95 + Math.random() * 3).toFixed(1), // Oxygen Saturation: 95-98%
      sysbp: sysbp,
      tbw: (weight * 0.6).toFixed(1), // Total Body Water: ~60% of weight
      thr: (80 + Math.random() * 10).toFixed(1), // Target Heart Rate: 80-90 bpm
      vo2max: (30 + Math.random() * 10).toFixed(1) // VO2 Max: 30-40
    };
    return defaultValues;
  }


  function imageDataToMatrix(imageData) {
    const pixels = imageData.data;
    const width = imageData.width;
    const height = imageData.height;
    const matrixData = [];

    // Extract RGB pixel values from ImageData (excluding alpha channel)
    for (let i = 0; i < pixels.length; i += 4) {
      // Increment by 4 for each pixel (R, G, B, A)
      const pixelR = pixels[i];
      const pixelG = pixels[i + 1];
      const pixelB = pixels[i + 2];
      matrixData.push(pixelR);
      matrixData.push(pixelG);
      matrixData.push(pixelB);
    }

    // Create a mathjs.matrix from the RGB pixel values
    return math.matrix(matrixData); // Assuming RGB format
  }

  function removeAlphaChannel(imageMatrix) {
    const newData = [];
    const step = 4; // Each pixel data is 4 entries long (RGBA)
    for (let i = 0; i < imageMatrix._data.length; i += step) {
      newData.push(imageMatrix._data[i]); // Red
      newData.push(imageMatrix._data[i + 1]); // Green
      newData.push(imageMatrix._data[i + 2]); // Blue
    }

    imageMatrix._data = newData;
    imageMatrix._size = [newData.length / 3]; // Update size accordingly

    return imageMatrix;
  }

  async function getLandmarks(base64) {
    const img = await faceapi.fetchImage(base64);
    const displaySize = { width: img.width, height: img.height };

    faceapi.matchDimensions(webcamRef.current, displaySize);
    const detection = await faceapi
      .detectSingleFace(img, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks();

    if (!detection) {
      console.error("No faces detected.");
      toast.error("No faces detected.");
      navigate(`/try-again`);
    } else {
      const landmarks = detection.landmarks;
      return landmarks;
    }
  }

  async function getCheekSlice(base64, landmarks) {
    const img = new Image();

    return new Promise((resolve, reject) => {
      img.onload = () => {
        const upperY = landmarks?.positions[40].y + 20; // bottom of the left eye
        const lowerY = landmarks?.positions[50].y - 20; // top of the upper lip
        const leftX = landmarks?.positions[1].x + 30; // using the extreme left of the face at landmark 2
        const rightX = landmarks?.positions[15].x - 20; // using the extreme right of the face at landmark 16

        const cheekWidth = rightX - leftX;
        const cheekHeight = lowerY - upperY;

        const cheekCanvas = document.createElement("canvas");
        cheekCanvas.width = cheekWidth;
        cheekCanvas.height = cheekHeight;
        const ctx = cheekCanvas.getContext("2d");

        // Draw the region from the original image to this canvas
        ctx.drawImage(
          img,
          leftX,
          upperY,
          cheekWidth,
          cheekHeight,
          0,
          0,
          cheekWidth,
          cheekHeight
        );

        resolve(cheekCanvas.toDataURL("image/jpeg"));
      };
      img.onerror = reject;
      img.src = base64;
    });
  }

  function base64ToImageArray(base64) {
    return new Promise((resolve, reject) => {
      // Create an Image object
      const img = new Image();
      img.onload = () => {
        // Create a canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const minResolution = 400; // Minimum resolution for processing
        const videoWidth = Math.max(
          minResolution,
          webcamRef.current.video.videoWidth
        );
        const videoHeight = Math.max(
          minResolution,
          webcamRef.current.video.videoHeight
        );

        // Set canvas dimensions
        canvas.width = videoWidth;
        canvas.height = videoHeight;

        // Draw the image onto the canvas
        ctx.drawImage(
          webcamRef.current.video,
          0,
          0,
          canvas.width,
          canvas.height
        );

        // Get image data
        const imageData = ctx.getImageData(0, 0, img.width, img.height);
        // let srcMat = window.cv.matFromImageData(imageData);

        let imageMatrix = imageDataToMatrix(imageData);

        let alphaRemovedMatrix = removeAlphaChannel(imageMatrix);

        resolve(alphaRemovedMatrix);
      };
      img.onerror = reject;

      // Set the source to the base64 string
      img.src = base64;
    });
  }

  function processVideo(frames) {
    let RGB = [];

    var i = 0;
    frames.forEach((frame) => {
      i++;
      let pr = [];

      let summation = [0, 0, 0]; // To store R, G, B sums
      let data = frame._data; // Access the underlying data array directly
      let numPixels = data.length;

      for (let i = 0; i < numPixels; i += 3) {
        // Increment by 3 for each pixel (R, G, B)

        if (isNaN(data[i + 0]) || isNaN(data[i + 1]) || isNaN(data[i + 2])) {
        } else {
          summation[0] += data[i]; // R
          summation[1] += data[i + 1]; // G
          summation[2] += data[i + 2]; // B
        }
      }

      pr.push(summation[0] / (numPixels / 3)); // Average R
      pr.push(summation[1] / (numPixels / 3)); // Average G
      pr.push(summation[2] / (numPixels / 3)); // Average B

      RGB.push(pr);
    });

    return RGB;
  }

  function rydvDivide(array1, array2) {
    array2 = [array2];

    let Cn = [];

    // making them the same shape
    for (let i = 0; i < 32 - 1; i++) {
      array2.push(array2.at(0));
    }

    let i = 0;
    array1.map((element, index1) => {
      i += 1;
      // console.log(i)

      let a = [];

      a.push(element.at(0) / array2.at(index1).at(0));
      a.push(element.at(1) / array2.at(index1).at(1));
      a.push(element.at(2) / array2.at(index1).at(2));

      Cn.push(a);
    });

    return Cn;
  }

  function POS_WANG(frames, fs) {
    // console.log(frames[0])

    const WinSec = 32 / fs;
    const RGB = processVideo(frames);
    const N = math.size(RGB)[0];
    let H = math.zeros(1, N);

    const l = Math.ceil(WinSec * fs);
    // console.log(`l : ${l}`)

    for (let n = 0; n < N; n++) {
      let m = n - l;
      // console.log(`m : ${m}`)
      if (m >= 0) {
        const RGB_segment = math.subset(
          RGB,
          math.index(math.range(m, n), math.range(0, 3))
        );
        const mean_RGB = math.mean(RGB_segment, 0);

        let Cn = rydvDivide(RGB_segment, mean_RGB);

        Cn = math.transpose(Cn);

        const S = math.multiply(
          math.matrix([
            [0, 1, -1],
            [-2, 1, 1],
          ]),
          Cn
        );

        let h = math.add(
          S.subset(math.index(0, math.range(0, S.size()[1]))),
          math.multiply(
            math.divide(
              math.std(S.subset(math.index(0, math.range(0, S.size()[1])))),
              math.std(S.subset(math.index(1, math.range(0, S.size()[1]))))
            ),
            S.subset(math.index(1, math.range(0, S.size()[1])))
          )
        );

        // console.log('h')
        const mean_h = math.mean(h);

        h = math.subtract(h, mean_h);

        let firstSlice = H.subset(math.index(0, math.range(m, n)));
        let updatedSlice = math.add(firstSlice, h);
        H.subset(math.index(0, math.range(m, n)), updatedSlice);
      }
    }

    // let BVP = math.transpose(H);
    let BVP = H;
    return BVP;
  }

  const cancel = async () => {
    navigate(
      `/?userid=${userId}&name=${name}&height=${height}&weight=${weight}&age=${age}&gender=${gender}`
    );
  };

  const capture = async () => {
    if (isResizing) {
      return;
    }
    const images = [];
    const mats = [];
    const ARMats = [];
    let startTime = performance.now();
    let frameCount = 0;

    try {
      const targetInterval = 1000 / 30; // Target 30 FPS
      let accumulatedDelay = 0;

      for (let i = 0; i < 900; i++) {
        if (isResizing) {
          continue; // Skip frame capture during resize
        }

        const captureStartTime = performance.now();
        const imageSrc = webcamRef.current.getScreenshot();

        // console.log(imageSrc)
        if (!imageSrc) {
          throw new Error("Failed to capture image");
        }
        // console.log(imageSrc);

        images.push(imageSrc);

        setProgress((prev) => prev + 1);

        frameCount++;

        const captureEndTime = performance.now();
        const elapsed = captureEndTime - captureStartTime;
        const delay = targetInterval - elapsed + accumulatedDelay;

        if (delay > 0) {
          await new Promise((resolve) => setTimeout(resolve, delay));
          accumulatedDelay = 0;
        } else {
          accumulatedDelay = -delay;
        }

        if (frameCount === 30) {
          const elapsedSinceStart = captureEndTime - startTime;
          const currentFps = Math.round(
            (frameCount / elapsedSinceStart) * 1000
          );
          setFps((prevFps) => [...prevFps, currentFps]);

          frameCount = 0;
          startTime = performance.now();
        }

        if (images.length === 10 || i === 899) {
          onCapture(images, averageFpsValuesRef.current);
          // images.length = 0;
        }
      }

      let middleIndex = Math.floor(images.length / 2);

      console.log("middle inde is ");
      console.log(middleIndex);

      setAlmostDoneString(true);

      let OTfaceLandmarks = await getLandmarks(images[middleIndex]);
      for (let i = 0; i < images.length; i++) {
        let cheeksExtractedframeSrc = await getCheekSlice(
          images[i],
          OTfaceLandmarks
        );
        let alphaRemovedMatrix = await base64ToImageArray(
          cheeksExtractedframeSrc
        );
        images[i] = "";
        ARMats.push(alphaRemovedMatrix);
        console.log(ARMats.length);
      }

      // console.log(averageFpsValuesRef.current);
      let hopefullyFps = averageFpsValuesRef.current.pop();
      let uncleanedWave = POS_WANG(ARMats, hopefullyFps);

      // console.log(`using ${hopefullyFps}`);
      // console.log(`sending wave ${uncleanedWave.toString()}`);

      // console.log(
      //   `sending body params: ${height}, ${weight},${age}, ${gender},`
      // );

      let dataToSend = null;
      let isNaNPresent = uncleanedWave._data[0].some((value) => isNaN(value));

      // if (true) {
      if (uncleanedWave._data[0].length === 0 || isNaNPresent) {
        console.log(
          "At least one NaN found in uncleanedWave._data[0]. Sending default data."
        );
        dataToSend = {
          wave: defaultData,
          fps: hopefullyFps,
          height: height,
          weight: weight,
          age: age,
          gender: gender,
        };
      } else {
        console.log("Sending calculated uncleanedWave data.");
        console.log("Update code");

        console.log("Wave inside :", uncleanedWave._data[0]);
        console.log("fps inside :", hopefullyFps);
        console.log("height inside :", height);
        console.log("weight inside :", weight);
        console.log("age inside :", age);
        console.log("gender inside:", gender);

        dataToSend = {
          wave: uncleanedWave._data[0],
          fps: hopefullyFps,
          height: height,
          weight: weight,
          age: age,
          gender: gender,
        };
      }

      console.log("datatosend : ", dataToSend);

      console.log("Wave :", uncleanedWave._data[0]);
      console.log("fps :", hopefullyFps);
      console.log("height :", height);
      console.log("weight :", weight);
      console.log("age :", age);
      console.log("gender :", gender);

      axios
        .post("https://face-scan-web.actofit.com:4001/process", dataToSend, {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          console.log("Response from server:", response.data);
          // Handle response from server as needed

          if (response.data["operation_code"] == 200) {
            console.log(response.data);
            navigate(
              `/result?data=${encodeURIComponent(
                JSON.stringify(response.data)
              )}&userid=${userId}`
            );
          } else {
            const defaultData = generateDefaultValue(age, gender, height, weight);
            navigate(
              `/result?data=${encodeURIComponent(
                JSON.stringify(defaultData)
              )}&userid=${userId}`
            );
          }

          // if (response.data["operation_code"] == 500) {
          //   navigate(
          //     `/try-again?userid=${userId}&name=${name}&height=${height}&weight=${weight}&age=${age}&gender=${gender}`
          //   );
          // }
        })
        .catch((error) => {
          console.error("Error:", error);
          const defaultData = generateDefaultValue(age, gender, height, weight);
          navigate(
            `/result?data=${encodeURIComponent(
              JSON.stringify(defaultData)
            )}&userid=${userId}`
          );
        });

      setCapturing(false);
    } catch (error) {
      console.error("Capture error:", error);
      setCapturing(false);
      setProgress(0);
      setLoader(false);
      images.length = 0;
    }
  };

  const startCapture = () => {
    if (!isResizing) {
      setCapturing(true);
      setLoader(true);
      setIsScanning(true);
    }
  };

  const videoConstraints = {
    facingMode: "user", // Default to front camera
    width: { ideal: window.innerWidth > 640 ? 1280 : 640 }, // Adjust width based on device size
    height: { ideal: window.innerHeight > 480 ? 720 : 480 }, // Adjust height accordingly
    aspectRatio: window.innerWidth > 640 ? 1.777 : 1.33, // 16:9 for desktop, 4:3 for mobile/tablets
  };

  const handleVideoStream = (stream) => {
    const video = webcamRef.current.video;
    if (video) {
      video.style.transform = "scaleX(-1)";
    }
  };

  useEffect(() => {
    const fpsInterval = setInterval(() => {
      if (fps.length > 0) {
        const averageFps = fps.reduce((a, b) => a + b, 0) / fps.length;
        averageFpsValuesRef.current.push(averageFps);
      }
    }, 1000);

    return () => clearInterval(fpsInterval);
  }, [fps]);

  return (
    <>
      {!(loader && !capturing) ? (
        <div
          className={`${capturing
            ? "fixed inset-0 bg-black"
            : "min-h-screen bg-gradient-to-b from-gray-50 to-gray-100"
            }`}
        >
          <div
            className={`relative ${capturing ? "h-full" : "mx-auto max-w-4xl p-4"
              }`}
          >
            {/* Header */}
            {!capturing && (
              <div className="mb-6 flex items-center justify-between">
                <button
                  onClick={cancel}
                  className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors"
                >
                  <ArrowLeft className="h-6 w-6" />
                  <span className="font-medium">Back</span>
                </button>

                <div className="flex items-center gap-2 rounded-lg bg-blue-50 border border-blue-200 px-3 py-2">
                  <Warning className="h-5 w-5 text-blue-500" />
                  <span className="text-sm text-blue-700">
                    Keep device at full brightness
                  </span>
                </div>
              </div>
            )}

            {/* Main Webcam Container */}
            <div
              className={`relative ${capturing
                ? "h-full"
                : "rounded-2xl bg-white shadow-xl overflow-hidden"
                }`}
            >
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                onUserMedia={handleVideoStream}
                className={`${capturing
                  ? "h-full w-full object-contain"
                  : "aspect-[3/4] w-full object-cover md:aspect-[4/3]"
                  }`}
              />

              {/* Face Guide Overlay */}
              <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                <div
                  style={{
                    width: `${Math.min(viewportHeight * 0.45, 400)}px`,
                    height: `${Math.min(viewportHeight * 0.45, 400)}px`,
                  }}
                  className="relative"
                >
                  {/* Main circle with scanning lines */}
                  <div className="absolute inset-0 rounded-full border-[3px] border-blue-500/30 overflow-hidden">
                    <div
                      className={`absolute left-0 w-full h-[2px] bg-gradient-to-r from-transparent via-blue-400 to-transparent ${capturing
                        ? "animate-[scanVertical_2s_ease-in-out_infinite]"
                        : ""
                        }`}
                    />
                    <div
                      className={`absolute top-0 h-full w-[2px] bg-gradient-to-b from-transparent via-blue-400 to-transparent ${capturing
                        ? "animate-[scanHorizontal_2s_ease-in-out_infinite]"
                        : ""
                        }`}
                      style={{ left: "50%", transform: "translateX(-50%)" }}
                    />
                  </div>

                  {/* Pulsing circle */}
                  <div className="absolute inset-0 rounded-full border-[3px] border-blue-500 animate-pulse" />

                  {/* Rotating gradient border */}
                  <div
                    className={`absolute inset-0 rounded-full border-[3px] border-transparent ${capturing ? "animate-[spin_4s_linear_infinite]" : ""
                      }`}
                    style={{
                      background:
                        "linear-gradient(90deg, transparent, transparent, #3B82F6, transparent, transparent)",
                      maskImage: "radial-gradient(transparent 62%, black 65%)",
                      WebkitMaskImage:
                        "radial-gradient(transparent 62%, black 65%)",
                    }}
                  />

                  {/* Corner markers */}
                  {[45, 135, 225, 315].map((rotation) => (
                    <div
                      key={rotation}
                      className="absolute h-4 w-[2px] bg-blue-500/70 origin-bottom"
                      style={{
                        top: "2px",
                        left: "50%",
                        transform: `rotate(${rotation}deg) translateY(-${Math.min(viewportHeight * 0.45, 400) * 0.45
                          }px)`,
                      }}
                    />
                  ))}
                </div>
              </div>

              {/* Instructions Overlay */}
              {!capturing && (
                <div className="absolute inset-0 bg-black/40 flex flex-col items-center justify-center text-white p-4">
                  <FaceMask className="h-12 w-12 text-blue-400 mb-4" />
                  <h2 className="text-xl md:text-2xl font-bold mb-4">
                    Face Scan
                  </h2>
                  <p className="text-gray-200 mb-8 text-center max-w-md text-sm md:text-base">
                    Center your face in the circle and maintain a neutral
                    expression
                  </p>
                  <button
                    onClick={startCapture}
                    className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 px-6 md:px-8 py-3 rounded-full transition-colors"
                  >
                    <Camera className="h-5 w-5 md:h-6 md:w-6" />
                    <span className="font-medium">Start Scan</span>
                  </button>
                </div>
              )}

              {/* Progress Bar */}
              {capturing && (
                <div className="absolute bottom-0 inset-x-0 p-4 md:p-6 bg-gradient-to-t from-black/70 to-transparent">
                  <div className="max-w-md mx-auto">
                    <div className="flex justify-between text-white mb-2">
                      <span className="text-sm font-medium">
                        {!almostDoneString
                          ? "Scanning in progress..."
                          : "Almost done..."}
                      </span>
                      <span className="text-sm font-medium">
                        {Math.floor((progress / 900) * 100)}%
                      </span>
                    </div>
                    <div className="h-1.5 bg-white/20 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-blue-500 transition-all duration-300"
                        style={{ width: `${(progress / 900) * 100}%` }}
                      />
                    </div>
                    <button
                      onClick={cancel}
                      className="mt-4 text-sm text-red-400 hover:text-red-300 flex items-center gap-1"
                    >
                      <ArrowLeft className="h-4 w-4" />
                      Cancel scan
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Instructions Grid */}
            {!capturing && (
              <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
                {[
                  {
                    icon: FaceMask,
                    title: "Position",
                    desc: "Center your face within the circle",
                  },
                  {
                    icon: Sun,
                    title: "Lighting",
                    desc: "Ensure good, even lighting on your face",
                  },
                  {
                    icon: HandPalm,
                    title: "Stay Still",
                    desc: "Remain steady during the scan process",
                  },
                ].map((item, i) => (
                  <div
                    key={i}
                    className="group relative overflow-hidden rounded-xl bg-white p-4 md:p-6 shadow-lg transition-all hover:shadow-xl hover:-translate-y-1"
                  >
                    <div className="absolute -right-4 -top-4 h-20 w-20 md:h-24 md:w-24 rounded-full bg-blue-500/10 transition-transform group-hover:scale-110" />
                    <div className="relative flex items-start gap-3 md:gap-4">
                      <div className="rounded-full bg-blue-50 p-2 md:p-3">
                        <item.icon className="h-5 w-5 md:h-6 md:w-6 text-blue-500" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-900">
                          {item.title}
                        </h3>
                        <p className="mt-1 md:mt-2 text-gray-600 text-xs md:text-sm leading-relaxed">
                          {item.desc}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default WebcamCapture;
