import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Score from '../../Components/Score/Score';
import axios from 'axios';
import './ExistResult.css'

const ExistResult = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const [data, setData] = useState({})

  useEffect(() => {
    const config = {
      method: 'get',
      url: `https://nucleus.actofit.com:3000/smartscale/v1/bwell/get_bwelldata?id=${id}`,
      headers: {
        'Content-Type': 'application/json'
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data)
        setData(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  return (
    <div className="reading-container">
      <div className="nav-button">
        Your Report
      </div>

      <div className="reading">
        <p className="reading-title">Heart Rate</p>
        <p className="reading-value">{data?.bpm}</p>
        <p className="reading-unit">BPM</p>
        <div className="reading-range">
          <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
            <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8" />
            {data?.bpm <= 60 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
            {data?.bpm > 60 && data?.bpm <= 101 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
            {data?.bpm > 101 && data?.bpm <= 220 && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
          </svg>
        </div>
        <p className="reading-head low">LOW</p>
        <p className="reading-head normal">NORMAL</p>
        <p className="reading-head high">HIGH</p>
        <p className="reading-threshold bpm-one">30</p>
        <p className="reading-threshold bpm-two">60</p>
        <p className="reading-threshold bpm-three">101</p>
        <p className="reading-threshold bpm-four">220</p>
      </div>

      <div className="reading">
        <p className="reading-title">Respiration Rate</p>
        <p className="reading-value">{data?.rr}</p>
        <p className="reading-unit">breaths/min</p>
        <div className="reading-range">
          <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
            <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8" />
            {data?.rr <= 12 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
            {data?.rr > 12 && data?.rr <= 21 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
            {data?.rr > 21 && data?.rr <= 30 && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
          </svg>
        </div>
        <p className="reading-head low">LOW</p>
        <p className="reading-head normal">NORMAL</p>
        <p className="reading-head high">HIGH</p>
        <p className="reading-threshold bpm-one">6</p>
        <p className="reading-threshold bpm-two">12</p>
        <p className="reading-threshold bpm-three">21</p>
        <p className="reading-threshold bpm-four">30</p>
      </div>

      <div className="reading">
        <p className="reading-title">Oxygen Saturation</p>
        <p className="reading-value">{data?.oxygen}</p>
        <p className="reading-unit">%</p>
        <div className="reading-range">
          <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
            <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8" />
            {data?.oxygen <= 91 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
            {data?.oxygen > 91 && data?.oxygen <= 95 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
            {data?.oxygen > 95 && data?.oxygen <= 100 && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
          </svg>
        </div>
        <p className="reading-head low">LOW</p>
        <p className="reading-head concerning">CONCERNING</p>
        <p className="reading-head high">NORMAL</p>
        <p className="reading-threshold bpm-one">80</p>
        <p className="reading-threshold bpm-two">91</p>
        <p className="reading-threshold bpm-three">95</p>
        <p className="reading-threshold bpm-four">100</p>
      </div>

      {data.diastolic && <div className="reading" style={{ "height": "212px" }}>
        <p className="reading-title">Blood Pressure</p>
        <p className="reading-value">{data?.systolic} <span className="units">mmHg</span></p>
        <p className="reading-unit">Systolic</p>
        <div className="reading-range">
          <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
            <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8" />
            {data?.systolic <= 100 && <rect x="0.724121" y="0.286621" width="54.81" height="6" fill="#ff4b28" />}
            {data?.systolic > 100 && data?.systolic <= 120 && <rect x="54.81" y="0.286621" width="54.81" height="6" fill="#00ab76" />}
            {data?.systolic > 120 && data?.systolic <= 139 && <rect x="109.62" y="0.286621" width="54.81" height="6" fill="#ffe400" />}
            {data?.systolic > 139 && data?.systolic <= 200 && <rect x="164.43" y="0.286621" width="54.81" height="6" fill="#ff4b28" />}
          </svg>
        </div>
        <p className="reading-head lows">LOW</p>
        <p className="reading-head normals">NORMAL</p>
        <p className="reading-head aboves">ABOVE N</p>
        <p className="reading-head highs">HIGH</p>
        <p className="reading-threshold bp-one">50</p>
        <p className="reading-threshold bp-two">100</p>
        <p className="reading-threshold bp-three">120</p>
        <p className="reading-threshold bp-four">139</p>
        <p className="reading-threshold bp-five">220</p>

        <div className="diastolic">
          <p className="reading-value">{data?.diastolic}<span className="units">mmHg</span></p>
          <p className="reading-unit">Diastolic</p>
          <div className="reading-range">
            <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
              <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8" />
              {data?.diastolic <= 70 && <rect x="0.724121" y="0.286621" width="54.81" height="6" fill="#ff4b28" />}
              {data?.diastolic > 70 && data?.diastolic <= 80 && <rect x="54.81" y="0.286621" width="54.81" height="6" fill="#00ab76" />}
              {data?.diastolic > 80 && data?.diastolic <= 90 && <rect x="109.62" y="0.286621" width="54.81" height="6" fill="#ffe400" />}
              {data?.diastolic > 90 && data?.diastolic <= 120 && <rect x="164.43" y="0.286621" width="54.81" height="6" fill="#ff4b28" />}
            </svg>
          </div>
          <p className="reading-head lows">LOW</p>
          <p className="reading-head normals">NORMAL</p>
          <p className="reading-head aboves">ABOVE N</p>
          <p className="reading-head highs">HIGH</p>
          <p className="reading-threshold bp-one">30</p>
          <p className="reading-threshold bp-two">70</p>
          <p className="reading-threshold bp-three">80</p>
          <p className="reading-threshold bp-four">90</p>
          <p className="reading-threshold bp-five">120</p>
        </div>

      </div>}

      {/* <div className="reading">
        <p className="reading-title">SDNN</p>
        <p className="reading-value">{data?.sdnn}</p>
        <p className="reading-unit">ms</p>
        <div className="reading-range">
                    <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
                        <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8"/>
                        {data?.sdnn <= 30 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
                        {data?.sdnn > 30 && data?.sdnn <= 97 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
                        {data?.sdnn > 97  && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
                    </svg>
            </div>
        <p className="reading-head low">LOW</p>
        <p className="reading-head concerning">NORMAL</p>
        <p className="reading-head high">HIGH</p>
        <p className="reading-threshold bpm-one">0</p>
        <p className="reading-threshold bpm-two">30</p>
        <p className="reading-threshold bpm-three">97</p>
        <p className="reading-threshold bpm-four">150</p>
      </div>
  
      <div className="reading">
        <p className="reading-title">RMSSD</p>
        <p className="reading-value">{data?.rmssd}</p>
        <p className="reading-unit">ms</p>
        <div className="reading-range">
                    <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
                        <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8"/>
                        {data?.rmssd <= 20 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
                        {data?.rmssd > 20 && data?.rmssd <= 90 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
                        {data?.rmssd > 90  && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
                    </svg>
            </div>
        <p className="reading-head low">LOW</p>
        <p className="reading-head concerning">NORMAL</p>
        <p className="reading-head high">HIGH</p>
        <p className="reading-threshold bpm-one">0</p>
        <p className="reading-threshold bpm-two">20</p>
        <p className="reading-threshold bpm-three">90</p>
        <p className="reading-threshold bpm-four">150</p>
      </div> */}

      <div className="reading">
        <p className="reading-title">IBI</p>
        <p className="reading-value">{data?.ibi}</p>
        <p className="reading-unit">ms</p>
        <div className="reading-range">
          <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
            <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8" />
            {data?.ibi <= 640 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
            {data?.ibi > 640 && data?.ibi <= 900 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
            {data?.ibi > 900 && data?.ibi <= 3000 && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
          </svg>
        </div>
        <p className="reading-head low">LOW</p>
        <p className="reading-head concerning">NORMAL</p>
        <p className="reading-head high">HIGH</p>
        <p className="reading-threshold bpm-one">0</p>
        <p className="reading-threshold bpm-two">640</p>
        <p className="reading-threshold bpm-three">900</p>
        <p className="reading-threshold bpm-four">3000</p>
      </div>

      {data?.asth_risk && <div className="reading">
        <p className="reading-title">Asthama Risk Score</p>
        <p className="reading-value">{data?.asth_risk}</p>
        <p className="reading-unit">ms</p>
        <div className="reading-range">
          <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
            <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8" />
            {data?.asth_risk <= 50 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
            {data?.asth_risk > 50 && data?.asth_risk <= 80 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
            {data?.asth_risk > 80 && data?.asth_risk <= 100 && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
          </svg>
        </div>
        <p className="reading-head low">NORMAL</p>
        <p className="reading-head concerning">ELEVATED</p>
        <p className="reading-head high">HIGH</p>
        <p className="reading-threshold bpm-one">0</p>
        <p className="reading-threshold bpm-two">50</p>
        <p className="reading-threshold bpm-three">80</p>
        <p className="reading-threshold bpm-four">100</p>
      </div>}

      <div className="congras">
        <span style={{ "fontWeight": "bold" }}>Disclaimer:</span> For Investigational Use Only. Actofit is not a substitute for the clinical judgment of a healthcare professional. Actofit is intended to improve your awareness of general wellness. Actofit does not diagnose, treat, mitigate or prevent any disease, symptom, disorder or abnormal physical state. Consult with a health care professional or emergency services if you believe you may have a medical issue.
      </div>
    </div>
  );
};

export default ExistResult;









{/* <div>
      <h1>Result</h1>
      {data && (
        <div>
          <h2>Success Message</h2>
          <p>{data?.message}</p>
         
        </div>
      )}
    </div> */}
{/* <div className="score-container">
            <div className="circle-ring">
            <div className="circle-gap">
                <div className="red-circle">
                <p className="centered-text">98</p>
                </div>
            </div>
            </div>
        </div> */}
{/* <Score percentage='8'/>
            
        <div> 
            <p className="title">
            Wellbeing Score
            </p>
            <p className="sub-title">
            EXCELLENT
            </p>
        </div>
        <div className="congras">
            <span style={{"font-Weight": "900"}}>Congratulations</span> , your body vitals are excellent! Your heart is beating strong, your blood pressure is steady, your oxygen level is optimal, and your breathing is easy. Keep up the good work and enjoy feeling your best.
        </div>  */}

