import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Instructions from "./Pages/Instructions/Instructions";
import Form from "./Pages/Form/Form";
import FaceScan from "./Pages/FaceScan/FaceScan";
import Result from "./Pages/Result/Result";
import TryAgain from "./Components/TryAgain/TryAgain";
import Loader from "./Components/Loader/Loader";
import ExistResult from "./Pages/ExistResult/ExistResult";
import UploadSpeedTest from "./Components/UploadSpeedTest/UploadSpeedTest";
import SpeedError from "./Components/SpeedError/SpeedError";
import HomePage from "./Pages/Homepage";
import { Toaster } from "react-hot-toast";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/" element={<Instructions />} /> */}
          <Route path="/" element={<HomePage />} />
          <Route path="/form" element={<Form />} />
          <Route path="/face-scan" element={<FaceScan />} />
          <Route path="/result" element={<Result />} />
          <Route path="/try-again" element={<TryAgain />} />
          <Route path="/exist-result" element={<ExistResult />} />
          <Route path="/loader" element={<Loader />} />
          <Route path="/upload-test" element={<UploadSpeedTest />} />
          <Route path="/speed-error" element={<SpeedError />} />
        </Routes>
      </Router>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </>
  );
};

export default App;
