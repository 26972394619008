import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Activity,
  Heart,
  Brain,
  Pulse,
  Drop,
  ThermometerSimple,
  CaretUp,
  CaretDown,
  Minus,
} from "@phosphor-icons/react";
import {
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = React.useState(false);

  if (hasError) {
    return (
      <div className="rounded-lg bg-red-50 p-4">
        <p className="text-red-800">
          Something went wrong rendering this component.
        </p>
      </div>
    );
  }

  return children;
};

const VitalCard = ({ title, value, unit, ranges, icon, interpretation }) => {
  const getStatusColor = (val, { low, high }) => {
    if (val < low) return "text-yellow-500 bg-yellow-50";
    if (val <= high) return "text-green-500 bg-green-50";
    return "text-red-500 bg-red-50";
  };

  const getProgressColor = (val, { low, high }) => {
    if (val < low) return "bg-yellow-500";
    if (val <= high) return "bg-green-500";
    return "bg-red-500";
  };

  return (
    <div className="rounded-xl bg-white p-6 shadow-lg transition-all hover:shadow-xl">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <div className={`rounded-full p-3 ${getStatusColor(value, ranges)}`}>
            {icon}
          </div>
          <div>
            <h3 className="font-semibold text-gray-900">{title}</h3>
            <div className="flex items-center space-x-2">
              <span className="text-2xl font-bold">
                {typeof value === "number" ? value.toFixed(1) : value}
              </span>
              <span className="text-sm text-gray-500">{unit}</span>
            </div>
          </div>
        </div>
        <div className="text-right">
          <div className={getStatusColor(value, ranges).split(" ")[0]}>
            {value > ranges.high ? (
              <CaretUp size={20} weight="bold" />
            ) : value < ranges.low ? (
              <CaretDown size={20} weight="bold" />
            ) : (
              <Minus size={20} weight="bold" />
            )}
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="mb-1 flex justify-between text-xs text-gray-500">
          <span>Low: &lt;{ranges.low}</span>
          <span>
            Normal: {ranges.low}-{ranges.high}
          </span>
          <span>High: &gt;{ranges.high}</span>
        </div>
        <div className="h-2 w-full rounded-full bg-gray-200">
          <div
            className={`h-2 rounded-full transition-all ${getProgressColor(
              value,
              ranges
            )}`}
            style={{
              width: `${Math.min(
                100,
                Math.max(
                  0,
                  ((value - ranges.low) / (ranges.high - ranges.low)) * 100
                )
              )}%`,
            }}
          />
        </div>
      </div>
      <div className="mt-4">
        <p className="text-sm text-gray-600">{interpretation}</p>
      </div>
    </div>
  );
};

const BloodPressureCard = ({ systolic, diastolic, status }) => {
  const ranges = {
    systolic: { optimal: 120, normal: 130, high: 140, crisis: 180 },
    diastolic: { optimal: 80, normal: 85, high: 90, crisis: 120 },
  };

  const getStatusClass = (value, range) => {
    if (value >= range.crisis) return "bg-red-50 text-red-700";
    if (value >= range.high) return "bg-orange-50 text-orange-700";
    if (value >= range.normal) return "bg-yellow-50 text-yellow-700";
    return "bg-green-50 text-green-700";
  };

  return (
    <div className="rounded-xl bg-white p-6 shadow-lg">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-semibold text-gray-900">Blood Pressure</h3>
        <span
          className={`px-3 py-1 rounded-full text-sm font-medium ${status === "HIGH"
            ? "bg-red-50 text-red-700"
            : status === "LOW"
              ? "bg-yellow-50 text-yellow-700"
              : "bg-green-50 text-green-700"
            }`}
        >
          {status}
        </span>
      </div>
      <div className="grid gap-8 md:grid-cols-2">
        <div>
          <div className="mb-4">
            <h4 className="text-gray-600 mb-2">Systolic</h4>
            <div className="flex items-baseline space-x-2">
              <span className="text-3xl font-bold text-gray-900">
                {systolic}
              </span>
              <span className="text-gray-500">mmHg</span>
            </div>
          </div>
          <div className="space-y-2">
            {Object.entries(ranges.systolic).map(([key, value]) => (
              <div key={key} className="flex items-center justify-between">
                <span className="text-sm text-gray-500 capitalize">{key}</span>
                <span
                  className={`text-sm ${systolic >= value
                    ? "font-semibold text-red-600"
                    : "text-gray-400"
                    }`}
                >
                  {value} mmHg
                </span>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="mb-4">
            <h4 className="text-gray-600 mb-2">Diastolic</h4>
            <div className="flex items-baseline space-x-2">
              <span className="text-3xl font-bold text-gray-900">
                {diastolic}
              </span>
              <span className="text-gray-500">mmHg</span>
            </div>
          </div>
          <div className="space-y-2">
            {Object.entries(ranges.diastolic).map(([key, value]) => (
              <div key={key} className="flex items-center justify-between">
                <span className="text-sm text-gray-500 capitalize">{key}</span>
                <span
                  className={`text-sm ${diastolic >= value
                    ? "font-semibold text-red-600"
                    : "text-gray-400"
                    }`}
                >
                  {value} mmHg
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ResultDashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jsonData = searchParams.get("data");
  const userId = searchParams.get("userid");

  useEffect(() => {
    const fetchData = async () => {
      if (!jsonData) return;

      try {
        const jsonMatch = jsonData.match(/\{.*\}/);
        if (!jsonMatch) return;

        const parsedData = JSON.parse(jsonMatch[0].replace(/'/g, '"'));
        console.log("Parsed data:", parsedData);
        setData(parsedData);

        await axios({
          method: "post",
          url: "https://nucleus.actofit.com:3000/smartscale/v1/bwell/add_bwelldata",
          headers: { "Content-Type": "application/json" },
          data: JSON.stringify({
            _id: "6752e70d0b352a70d0e093e2",
            userid: userId,
            ibi: parsedData.ibi,
            rmssd: parsedData.rmssd,
            sdnn: parsedData.sdnn,
            bpm: parsedData.hr, // Updated to use hr
            rr: parsedData.rr,
            oxygen: parsedData.spo2, // Updated to use spo2
            stressStatus: "LOW", // Derived from si (stress index) if needed
            bloodPressureStatus: "NORMAL", // Can be derived from sysbp/diabp
            systolic: parsedData.sysbp,
            diastolic: parsedData.diabp,
            createdAt: Date.now(),
            __v: 0,
          }),
        });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [jsonData, userId]);

  // Improved normalization function for radar chart
  const normalizeForRadar = (value, min, max, ideal) => {
    if (value === undefined || value === null) return 0;

    // Center the value around the ideal point and normalize to 0-100
    const range = max - min;
    const normalizedValue = ((value - min) / range) * 100;
    return Math.min(100, Math.max(0, normalizedValue));
  };

  const radarData = data
    ? [
      {
        metric: "Heart Rate",
        value: normalizeForRadar(data.hr, 60, 100, 75),
        fullMark: 100,
      },
      {
        metric: "SpO2",
        value: normalizeForRadar(data.spo2, 95, 100, 98),
        fullMark: 100,
      },
      {
        metric: "Resp Rate",
        value: normalizeForRadar(data.rr, 12, 20, 16),
        fullMark: 100,
      },
      {
        metric: "HRV",
        value: normalizeForRadar(data.rmssd, 20, 100, 50),
        fullMark: 100,
      },
      {
        metric: "SI",
        value: normalizeForRadar(data.si, 0, 150, 50),
        fullMark: 100,
      },
    ]
    : [];

  if (loading || !data) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Activity
          className="animate-spin text-blue-500"
          size={32}
          weight="bold"
        />
      </div>
    );
  }

  const getBloodPressureStatus = (systolic, diastolic) => {
    if (systolic >= 140 || diastolic >= 90) return "HIGH";
    if (systolic <= 90 || diastolic <= 60) return "LOW";
    return "NORMAL";
  };

  return (
    <div className="min-h-screen bg-gray-50 px-4 py-8 md:px-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 md:text-3xl">
          Health Metrics
        </h1>
        <p className="mt-1 text-sm text-gray-600 md:text-base">
          Generated on: {new Date().toLocaleString()}
        </p>
      </div>

      <div className="mb-8">
        <ErrorBoundary>
          <div className="rounded-xl bg-white p-6 shadow-lg">
            <h3 className="mb-4 text-lg font-semibold text-gray-900">
              Vital Signs Overview
            </h3>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <RadarChart
                  data={radarData}
                  margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                >
                  <PolarGrid />
                  <PolarAngleAxis dataKey="metric" />
                  <PolarRadiusAxis angle={90} domain={[0, 100]} />
                  <Radar
                    name="Vitals"
                    dataKey="value"
                    stroke="#3b82f6"
                    fill="#3b82f6"
                    fillOpacity={0.5}
                  />
                </RadarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </ErrorBoundary>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        <VitalCard
          title="Heart Rate"
          value={Number(data.hr)}
          unit="bpm"
          ranges={{ low: 60, high: 100 }}
          icon={<Heart size={24} weight="fill" />}
          interpretation="Heart beats per minute"
        />
        <VitalCard
          title="Oxygen Saturation"
          value={Number(data.spo2)}
          unit="%"
          ranges={{ low: 95, high: 100 }}
          icon={<Drop size={24} weight="fill" />}
          interpretation="Blood oxygen saturation"
        />
        <VitalCard
          title="Respiratory Rate"
          value={Number(data.rr)}
          unit="br/min"
          ranges={{ low: 12, high: 20 }}
          icon={<Pulse size={24} weight="fill" />}
          interpretation="Breaths per minute"
        />
        {/* <VitalCard
          title="RMSSD"
          value={Number(data.rmssd)}
          unit="ms"
          ranges={{ low: 20, high: 100 }}
          icon={<Activity size={24} weight="fill" />}
          interpretation="Heart rate variability"
        />
        <VitalCard
          title="SDNN"
          value={Number(data.sdnn)}
          unit="ms"
          ranges={{ low: 20, high: 100 }}
          icon={<Activity size={24} weight="fill" />}
          interpretation="Standard deviation of NN intervals"
        /> */}
        <VitalCard
          title="IBI"
          value={Number(data.ibi)}
          unit="ms"
          ranges={{ low: 500, high: 1000 }}
          icon={<Activity size={24} weight="fill" />}
          interpretation="Inter-beat interval"
        />
      </div>

      <div className="mt-6">
        <BloodPressureCard
          systolic={Number(data.sysbp)}
          diastolic={Number(data.diabp)}
          status={getBloodPressureStatus(data.sysbp, data.diabp)}
        />
      </div>
    </div>
  );
};

export default ResultDashboard;
